/* @import url('https://docs.dhtmlx.com/gantt/codebase/dhtmlxgantt.css?v=9.0.3'); */

/* html,
body,
#root,
#root div:only-child,
.gantt-container {
    height: 100%;
} */

.gantt-container {
    height: calc(100vh - 100px);
}

.gantt_bg_color,
.gantt_bg_color.odd {
    background: #3ac944;
}

.gantt_missing_color,
.gantt_missing_color.odd {
    background: #ffb3b3;
}

.gantt_newlyadded_color,
.gantt_newlyadded_color.odd {
    background: #ffff00;
}